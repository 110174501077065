import {
  set,
  setDeep,
  currentUser,
  retrieveObject,
  retrieveSet,
  trackScannedUuid,
} from "@/util/vuex";
import { getIcon } from "@/util/icons";
import { filterItems, getUTCNow } from "@/util/helpers";
import axios from "axios";
import find from "lodash/fp/find";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import Delivery from "../../models/growops/delivery/Delivery";
import DeliveryBox from "../../models/growops/delivery/Box";
import DeliveryBoxType from "../../models/growops/delivery/BoxType";
import DeliveryLine from "../../models/growops/delivery/Line";
import FulfillmentDetails from "../../models/growops/delivery/FulfillementDetails";
import { DateTime } from "luxon";
import FulfillmentLot from "../../models/growops/delivery/FulfillmentLot";

/**
 * TODO: Move delivery types to shared_config table
 */
const DELIVERY_TYPES: { [key: string]: any } = {
  delivery: {
    text: "Delivery",
    value: "delivery",
    icon: getIcon("delivery", "delivery"),
  },
  pickup: {
    text: "Pickup",
    value: "pickup",
    icon: getIcon("delivery", "pickup"),
  },
};

/**
 * TODO: Add box types to growops_delivery_box_types table
 */
const DELIVERY_BOX_TYPES: any = [
  "Crate",
  "CS04",
  "CS48T",
  "H1/6",
  "H4.0",
  "HC04P",
  "Herb4oz",
  "ST120",
  "Tomato",
  "Other",
  "None",
];

/**
 * TODO: Move delivery label types to shared_config table
 */
const DELIVERY_LABEL_OPTIONS: { [key: string]: any } = {
  standard: {
    text: "Standard",
    value: "standard",
    icon: getIcon("label", "default"),
  },
  usfoods: {
    text: "US Foods",
    value: "usfoods",
    icon: getIcon("label", "default"),
  },
  pti: {
    text: "PTI",
    value: "pti",
    icon: getIcon("label", "default"),
  },
  wf: {
    text: "Whole Foods",
    value: "wf",
    icon: getIcon("label", "default"),
  },
  master: {
    text: "Master",
    value: "master",
    icon: getIcon("label", "default"),
  },
  gianteagle: {
    text: "Giant Eagle",
    value: "gianteagle",
    icon: getIcon("label", "default"),
  },
};

/**
 * Set store defaults for easy resetting
 */
const DELIVERY_STORE_DEFAULTS = (): { [key: string]: any } => {
  return {
    deliveries: [],
    deliveryIds: [],
    deliveriesForManifest: [],
    currentDelivery: {},
    currentDeliveryBoxes: [],
    boxTypes: [],
    fulfillmentDetails: [],
    fulfillmentLots: [],
    deliveriesAggregateTotal: 0,
    refreshInterval: 60000,
  };
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = DELIVERY_STORE_DEFAULTS();

// ---------------------------------------------------------------------------
// MUTATIONS
// ---------------------------------------------------------------------------

const mutations = {
  setDeliveries: setDeep("deliveries"),
  setDeliveryIds: setDeep("deliveryIds"),
  setDeliveriesAggregateTotal: set("deliveriesAggregateTotal"),
  setDeliveriesForManifest: setDeep("deliveriesForManifest"),
  setCurrentDelivery: set("currentDelivery"),
  setBoxTypes: setDeep("boxTypes"),
  setCurrentDeliveryBoxes: setDeep("currentDeliveryBoxes"),
  setFulfillmentDetails: setDeep("fulfillmentDetails"),
  setFulfillmentLots: setDeep("fulfillmentLots"),
  reset(state: any, property: string) {
    state[property] = DELIVERY_STORE_DEFAULTS()[property];
  },
};

// ---------------------------------------------------------------------------
// GETTERS
// ---------------------------------------------------------------------------

const getters = {
  /**
   * Returns the icon value for a given delivery type
   * @returns {string}
   */
  getDeliveryTypeIcon: (state: any) => (type: string) => {
    return DELIVERY_TYPES[type] ? DELIVERY_TYPES[type].icon : "";
  },

  /**
   * Returns the text value for a given delivery type
   * @returns {string}
   */
  getDeliveryTypeLabel: (state: any) => (type: string) => {
    return DELIVERY_TYPES[type] ? DELIVERY_TYPES[type].text : "";
  },

  /**
   * Returns an array of box types for use in a dropdown
   * @returns {Array}
   */
  getBoxTypeOptions: (state: any) => {
    if (isEmpty(state.boxTypes)) return [];
    return map(state.boxTypes, (type: any) => {
      return {
        text: type.name,
        value: type.uuid,
      };
    });
  },

  /**
   * Returns a box type object given a uuid
   * @returns {Object}
   */
  getBoxTypeByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find({ uuid: uuid }, state.boxTypes) || {};
    },

  /**
   * Returns the text value for a given label type
   * @returns {string}
   */
  getDeliveryLabelTypeLabel: (state: any) => (type: string) => {
    return DELIVERY_LABEL_OPTIONS[type]
      ? DELIVERY_LABEL_OPTIONS[type].text
      : "";
  },

  /**
   * Returns a single display order by uuid
   * @returns {Object}
   */
  getDeliveryByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (uuid) {
        return find(["uuid", uuid], state.deliveries);
      }
      return {};
    },

  /**
   * Filters deliveries from routed filters
   * @returns {Array}
   */
  getFilteredDeliveries: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters = rootGetters["filter/getRoutedFilters"]["deliveries"] || [];
    let search = rootState.filter.search.deliveries || "";
    return filterItems(state.deliveries, filters, search);
  },

  getFulfillmentLotBySalesOrderLine:
    (state: any) =>
    (solId = "") => {
      if (!solId) return [];
      return (state.fulfillmentLots || []).filter(
        (l: any) => l.sales_order_line_id === solId
      );
    },
};

// ---------------------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------------------

const actions = {
  // DELIVERIES --------------------------------------------------------------
  // -------------------------------------------------------------------------
  retrieveTemplatedDeliveries: function (context: any, { facilityId }: any) {
    let deliveryTemplate = new Delivery();
    let returnQuery: string = deliveryTemplate.getReturnQuery(
      `{ facility_id: { _eq: "${facilityId}" },
      delivery_boxes: {packed: {_is_null: true}}}`,
      `{ date: desc }`
    );
    if (!returnQuery) return;
    return retrieveSet(
      context,
      returnQuery,
      "setDeliveries",
      deliveryTemplate.table
    );
  },

  /**
   * Retrieves sales order lines that are able to be packed out
   * @param {Object} dateRange Object with start and end date
   * @param {string} facilityId Current facility id
   */
  retrieveDeliveriesForManifest: function (
    context: any,
    { dateRange = {}, facilityIds }: any
  ) {
    let line: Delivery = new Delivery();
    let query: string = line.getReturnQueryForManifest(dateRange, facilityIds);
    if (!query) return;

    return retrieveSet(context, query, "setDeliveriesForManifest", line.table);
  },
  /**
   * Retrieves all deliveries for a facility, sorted by name.
   * Commits orders using `setDeliveries`.
   * @param context Store module context
   * @param {string} facilityId
   */

  retrieveDeliveries: function (
    context: any,
    {
      where = "",
      pagination = {},
      sortBy = "",
      useView = false,
      returnString = null,
    }: any = {}
  ) {
    let deliveryTemplate = new Delivery();

    if (!returnString) {
      returnString = useView
        ? deliveryTemplate.queryReturnPagination
        : deliveryTemplate.queryReturn;
    }

    let returnQuery: string = deliveryTemplate.getReturnQuery(
      where,
      sortBy,
      returnString,
      pagination,
      useView
    );
    if (!returnQuery) return;
    return retrieveSet(
      context,
      returnQuery,
      "setDeliveries",
      useView ? deliveryTemplate.view : deliveryTemplate.table
    );
  },

  retrieveDeliveryIds: function (context: any, { where = "" }: any = {}) {
    if (!where) return;
    let deliveryTemplate = new Delivery();
    let returnQuery: string = deliveryTemplate.getReturnQuery(
      where,
      "",
      "uuid",
      {},
      true
    );
    if (!returnQuery) return;
    return retrieveSet(
      context,
      returnQuery,
      "setDeliveryIds",
      deliveryTemplate.view
    );
  },

  /**
   * Retrieves a delivery by uuid.
   * Commits delivery using `setCurrentDelivery`.
   * @param context Store module context
   * @param {string} uuid Delivery UUID
   */
  retrieveCurrentDelivery: function (
    context: any,
    { uuid, number, useView = false }: any
  ) {
    let deliveryTemplate = new Delivery();
    let returnQuery: string;
    if (number) {
      returnQuery = deliveryTemplate.getReturnQuery(
        `{number: {_eq: "${number}"}}`
      );
    } else {
      returnQuery = deliveryTemplate.getReturnQuery(
        `{uuid: {_eq: "${uuid}"}}`,
        "",
        useView ? deliveryTemplate.getQueryReturnTemplate(true) : "",
        {},
        useView
      );
    }
    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentDelivery",
      useView ? deliveryTemplate.view : deliveryTemplate.table
    );
  },

  /**
   * Saves a delivery.
   * Commits saved delivery using `setCurrentDelivery`.
   * @param context Store module context
   * @param {Object} delivery Delivery save-version object
   * @param {boolean} refreshDeliveries Refreshes facility deliveries if true
   */
  saveDelivery: function (
    context: any,
    { delivery, refreshDeliveries = true }: any
  ) {
    let deliveryTemplate = new Delivery();
    let upsertQuery: string = deliveryTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: delivery,
          },
        })
        .then(
          (success: any) => {
            // Store the saved delivery as current delivery
            let queryName: string = `insert_${deliveryTemplate.table}`;
            context.commit(
              "setCurrentDelivery",
              success.data.data[queryName].returning[0]
            );
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  loadDeliveryBoxes: function (
    context: any,
    { delivery, refreshDeliveries = true }: any
  ) {
    // If the delivery has a null or empty uuid, do not update anything
    if (!delivery.uuid) return;
    let loadedBy = currentUser(context).uuid || "";
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `mutation loadDeliveryBoxes {
            update_growops_delivery_boxes(
              where: {_and: {delivery_id: {_eq: "${
                delivery.uuid
              }"}, archived: {_is_null: true}}},
              _set: {
                loaded: "${getUTCNow()}",
                loaded_by: "${loadedBy}",
                loaded_in: "Kiosk"
              }
            ) {
              affected_rows
            }
          }`,
        })
        .then(
          (success: any) => {
            // Refresh deliveries
            if (refreshDeliveries) {
              let facilityId: string =
                context.rootState.facilities.currentFacilityUuid;
              if (facilityId) {
                context.dispatch("retrieveDeliveries", {
                  facilityId: facilityId,
                });
              }
            }
            // Or refresh the current delivery
            else {
              context.dispatch("retrieveCurrentDelivery", {
                uuid: delivery.uuid,
              });
            }
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  packDeliveryBoxes: function (
    context: any,
    { delivery, unpack = false }: any
  ) {
    // If the delivery has a null or empty uuid, do not update anything
    if (!delivery.uuid) return;
    let packed: string | null = getUTCNow();
    let packedBy: string | null = currentUser(context).uuid || "";
    // Because hasura
    packed = `"${packed}"`;
    packedBy = `"${packedBy}"`;
    if (unpack) {
      packed = null;
      packedBy = null;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `mutation packDeliveryBoxes {
            update_growops_delivery_boxes(
              where: {_and: {
                delivery_id: {_eq: "${delivery.uuid}"},
                archived: {_is_null: true},
                packed: {_is_null: true}
              }},
              _set: {
                packed: ${packed},
                packed_by: ${packedBy}
              }
            ) {
              affected_rows
            }
          }`,
        })
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Saves a delivery box.
   * Retrieves parent delivery using `retrieveCurrentDelivery`.
   * @param context Store module context
   * @param {Object} box Box save-version object
   */
  saveBox: function (context: any, { box, lots, reverseMode }: any) {
    let removeLines: boolean = false;
    let deliveryId: string = "";
    let upsertQuery: string = "";
    let boxTemplate = new DeliveryBox();
    if (Array.isArray(box)) {
      deliveryId = box[0].delivery_id;
      removeLines = true;
      let uuids = box.reduce((u: any[], b: any) => {
        if (b.uuid) {
          u.push(b.uuid);
        }
        return u;
      }, []);
      upsertQuery = boxTemplate.getArrayUpsertQuery(uuids || [], removeLines);
    } else {
      removeLines = !box.archived;
      deliveryId = box.delivery_id;
      upsertQuery = boxTemplate.getUpsertQuery(box.uuid || "", removeLines);
    }
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      try {
        trackScannedUuid(
          context,
          Array.isArray(lots) ? lots.map((lot: any) => lot.uuid) : lots,
          reverseMode
        );
      } catch (error: any) {
        reject(error);
        return;
      }
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: box,
          },
        })
        .then(
          (success: any) => {
            // Refresh parent delivery
            if (deliveryId) {
              context.dispatch("retrieveCurrentDelivery", {
                uuid: deliveryId,
              });
            }
            resolve(success);
          },
          (fail) => {
            trackScannedUuid(
              context,
              Array.isArray(lots) ? lots.map((lot: any) => lot.uuid) : lots,
              true
            );
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Deletes a delivery.
   * Retrieves parent delivery using `retrieveDeliveries`.
   * @param context Store module context
   * @param {Object} delivery Delivery save-version object
   */
  archiveDelivery: function (context: any, { delivery, user }: any) {
    // GraphQL will fail if the uuid exists and is empty
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `
          mutation {
            update_growops_new_deliveries(where: {uuid: {_eq: "${
              delivery.uuid
            }"}}, _set: {archived_by: "${
            user.oid
          }", archived: "${DateTime.now().toISO()}"}) {
              affected_rows
            }
          }`,
        })
        .then(
          (success: any) => {
            context.dispatch("retrieveDeliveries", {
              facilityId: delivery.facility_id,
            });
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Deletes a delivery box.
   * Retrieves parent delivery using `retrieveCurrentDelivery`.
   * @param context Store module context
   * @param {Object} box Box save-version object
   */
  deleteBox: function (context: any, { box }: any) {
    if (!box.uuid) return;
    let boxTemplate = new DeliveryBox();
    let inject = `
      delete_growops_delivery_lines(where: {box_id: { _eq: "${box.uuid}" }}) {
        affected_rows
      }
    `;
    let deleteQuery: string = boxTemplate.getDeleteQuery(
      `{uuid: {_eq: "${box.uuid}"}}`,
      inject,
      "before"
    );
    if (!deleteQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: deleteQuery,
        })
        .then(
          (success: any) => {
            // Refresh parent delivery
            if (box.delivery_id) {
              context.dispatch("retrieveCurrentDelivery", {
                uuid: box.delivery_id,
              });
            }
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Completes a delivery. Sets accepted and rejected values for
   * delivery lines.
   * Commits saved delivery using `setCurrentDelivery`.
   * @param context Store module context
   * @param {Array} deliveryLines Delivery lines array
   * @param {Object} delivery Delivery object
   */
  completeDelivery: function (context: any, { deliveryLines, delivery }: any) {
    let lineTemplate = new DeliveryLine();
    let lineInsert: string = `
      insert_${lineTemplate.table}(
        objects: $lines,
        on_conflict: {
          constraint: ${lineTemplate.primaryKey},
          update_columns: [accepted, rejected]
        }
      ) {
        affected_rows
      }    
    `;

    let deliveryTemplate = new Delivery();
    let deliveryInsert: string = `
      insert_${deliveryTemplate.table}(
        objects: $delivery,
        on_conflict: {
          constraint: ${deliveryTemplate.primaryKey},
          update_columns: [completed, completed_by]
        }
      ) {
        returning {
          ${deliveryTemplate.queryReturn}
        }
      }    
    `;

    let query: string = `
      mutation completeDelivery(
        $lines: [${lineTemplate.table}_insert_input!]!,
        $delivery: [${deliveryTemplate.table}_insert_input!]!
      ) {
        ${lineInsert}
        ${deliveryInsert}
      }
    `;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: query,
          variables: {
            lines: deliveryLines,
            delivery: delivery,
          },
        })
        .then(
          (success: any) => {
            // Store the saved delivery as current delivery
            let queryName: string = `insert_${deliveryTemplate.table}`;
            context.commit(
              "setCurrentDelivery",
              success.data.data[queryName].returning[0]
            );
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Retrieves delivery box types, sorted by name.
   * Commits box types using `setBoxTypes`.
   * @param context Store module context
   */
  retrieveDeliveryBoxTypes: function (context: any) {
    let boxTypeTemplate = new DeliveryBoxType();
    let returnQuery: string = boxTypeTemplate.getReturnQuery(
      "",
      `{ name: desc }`
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setBoxTypes",
      boxTypeTemplate.table
    );
  },

  retrieveDeliveryBoxes: function (context: any, { delivery = "" }) {
    let boxTemplate = new DeliveryBox();
    let returnQuery = boxTemplate.getReturnQuery(
      `{delivery_id:{_eq:"${delivery}"}}`
    );

    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setCurrentDeliveryBoxes",
      boxTemplate.table
    );
  },

  /**
   * Retrieves fulfillment details, given a delivery id,
   * sales order line id, or both.
   * Commits details using `setFulfillmentDetails`.
   * @param context Store module context
   */
  retrieveFulfillmentDetails: function (
    context: any,
    { where = "", order_by = "" }: any
  ) {
    let fulfilmentDetailsTemplate = new FulfillmentDetails();
    let returnQuery = fulfilmentDetailsTemplate.getReturnQuery(where, order_by);

    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setFulfillmentDetails",
      "growops_quicker_fulfillment_qty_vw"
    );
  },

  /**
   * Retrieves fulfillment lots, given a where filter
   * Commits details using `setFulfillmentLots`.
   * @param context Store module context
   */
  retrieveFulfillmentLots: function (
    context: any,
    { where = "", order_by = "" }: any
  ) {
    const template = new FulfillmentLot();
    const returnQuery = template.getReturnQuery(where, order_by);

    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setFulfillmentLots",
      template.view
    );
  },

  /**
   * Retrieves delivery config from shared store module.
   * @param context Store module context
   */
  updateConfig: function (context: any) {
    let config: any = context.rootGetters["shared/getConfigByModel"](
      "growops",
      "Delivery"
    );
    if (isEmpty(config)) {
      context.dispatch(
        "shared/retrieveConfig",
        {
          app: "growops",
          model: "Delivery",
        },
        { root: true }
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
