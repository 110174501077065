import { Vue as _Vue } from "vue/types/vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import filter from "lodash/filter";
import { DateTime } from "luxon";
import md5 from "js-md5";
import Dinero from "dinero.js";
import sanitizeHtml, { DisallowedTagsModes } from "sanitize-html";
import startCase from "lodash/startCase";

export async function waitForReadystate() {
  if (typeof document !== "undefined" && document.readyState !== "complete") {
    await new Promise((resolve) => {
      const cb = () => {
        window.requestAnimationFrame(resolve);
        window.removeEventListener("load", cb);
      };
      window.addEventListener("load", cb);
    });
  }
}

export function toTitleCase(name: string | undefined) {
  // Capitalize all words in string if space is detected
  const space = " ";
  // @ts-ignore
  if (name.includes(space)) {
    // @ts-ignore
    return name.replace(/(^\w)|([-\s]\w)/g, (l) => l.toUpperCase());
  }

  // Capitalize string
  // @ts-ignore
  if (!name.includes(space)) {
    return upperFirst(camelCase(name));
  }
  return "";
}

export function isObject(value: any) {
  return typeof value === "object" && value !== null;
}

export function toOrdinal(number: number) {
  const lastDigit = number % 10;
  switch (lastDigit) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
}

export function getFileExtension(filename = "", includeDot = true) {
  const re = /(?:\.([^.]+))?$/;
  const extensions = re.exec(filename) || [];
  return includeDot ? extensions[0] : extensions[1];
}

export function formatCurrency(value: number | string = 0) {
  value = Math.round(Number(value) * 100);
  return Dinero({ amount: value }).toFormat("0.00");
}

export function emailToName(email: string) {
  const splitEmail: any = email.split("@")[0].split(".");
  let name: string;
  if (splitEmail.length === 1) {
    return toTitleCase(splitEmail[0]);
  }
  name = splitEmail[0].replace(/^\w/, (c: string) => c.toUpperCase()) + " ";
  name += splitEmail[1].replace(/^\w/, (c: string) => c.toUpperCase());
  return name;
}

export function timestampToDateTime(timestamp: string) {
  return DateTime.fromISO(timestamp).toFormat("ccc, LLL d yyyy `at` t");
}

export function timestampToDate(timestamp: string) {
  return DateTime.fromISO(timestamp).toFormat("ccc, LLL d yyyy");
}

export function timestampToSimpleDate(timestamp: string) {
  return DateTime.fromISO(timestamp).toFormat("MM/dd/yy");
}

/**
 * This function can be used for
 * default date time format and also
 * for to add or remove time with
 * configurable hours, minutes, seconds,
 * @param timestamp
 * @param type [local]
 * @param hours [0]
 * @param minutes [0]
 * @param seconds [0]
 * @returns 'yyyy-MM-dd HH:mm:ss || MM/dd/yyyy, HH:mm AM'
 */
export function timestampToSimpleDateTime(
  timestamp: string,
  type: string = "local",
  hours: number = 0,
  minutes: number = 0,
  seconds: number = 0
) {
  switch (type) {
    case "date-time": {
      return DateTime.fromISO(timestamp).toFormat("yyyy-MM-dd HH:mm:ss");
    }
    case "add-time": {
      return DateTime.fromISO(timestamp)
        .plus({
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        })
        .toFormat("yyyy-MM-dd HH:mm:ss");
    }
    case "sub-time": {
      return DateTime.fromISO(timestamp)
        .minus({
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        })
        .toFormat("yyyy-MM-dd HH:mm:ss");
    }
    default: {
      return DateTime.fromISO(timestamp).toLocaleString(
        DateTime.DATETIME_SHORT
      );
    }
  }
}

export function timestampToCondensedSimpleDate(timestamp: string) {
  return DateTime.fromISO(timestamp).toFormat("M/d/yy");
}

export function timestampToSimpleDateYearless(timestamp: string) {
  return DateTime.fromISO(timestamp).toFormat("MM/dd");
}

export function timestampToDatePicker(timestamp: string, utcFormatted = false) {
  if (utcFormatted) return DateTime.utc().toFormat("yyyy-MM-dd");
  return DateTime.fromISO(timestamp).toFormat("yyyy-MM-dd");
}

export function timestampToActivity(timestamp: string) {
  const out = DateTime.fromISO(timestamp).toRelative();
  return out === "in a few seconds" ? "just now" : out!;
}

export function formatTimestamp(timestamp: string, type = "datetime") {
  if (!DateTime.fromISO(timestamp).isValid) return timestamp;
  switch (type) {
    case "date": {
      return timestampToDate(timestamp);
    }
    case "activity": {
      return timestampToActivity(timestamp);
    }
    case "simple": {
      return timestampToSimpleDate(timestamp);
    }
    case "simple-condensed": {
      return timestampToSimpleDateYearless(timestamp);
    }
    case "simple-datetime": {
      return timestampToSimpleDateTime(timestamp);
    }
    case "simple-yearless": {
      return timestampToSimpleDateYearless(timestamp);
    }
    case "datepicker": {
      return timestampToDatePicker(timestamp);
    }
    default: {
      return timestampToDateTime(timestamp);
    }
  }
}

export function getUTCNow() {
  return DateTime.utc().toISO();
}

export function getUTCFromDate(date = "") {
  if (!DateTime.fromISO(date).isValid) return date;
  return DateTime.fromISO(date).toUTC().toISO();
}

export function getToday() {
  return getUTCNow();
}

export function getTomorrow() {
  return getDaysFromToday(1);
}

export function getDaysFromToday(days = 0) {
  return DateTime.now().plus({ days: days }).toUTC().toISO();
}

export function timestampFromDate(date: string) {
  if (!DateTime.fromISO(date).isValid) return date;
  const timestamp: string = DateTime.fromISO(date).toUTC().toISO();
  return timestamp;
}

export function timestampFromStartOfDay() {
  return DateTime.now().startOf("day").toUTC().toISO();
}

export function timestampFromEndOfDay() {
  return DateTime.now().endOf("day").toUTC().toISO();
}

export function filterItems(
  items: any = [],
  filters: any = [],
  search = "",
  searchColumns: Array<string> = []
) {
  if (items.length === 0) return items;
  for (const f of filters) {
    // If the filter field is search, stringify the entire
    // item and filter by value
    if (f.field === "search") {
      items = filter(items, (s: any) => {
        return filterSearch(s, f.value, searchColumns);
      });
    }
    // Otherwise, try to match the filter field with a field
    // in the item. Then check against value
    else {
      items = filter(items, (s: any) => {
        if (s[f.field] && !f.exclude) {
          return filterCompareValues(s[f.field], f.value);
        }
        // Allows for exlusion of field, for example, excluding
        // order with complete stage
        else if (s[f.field] && f.exclude) {
          return !filterCompareValues(s[f.field], f.value);
        }
        return false;
      });
    }
  }
  // Check against temporary search filter
  if (search.length > 0) {
    items = filter(items, (s: any) => {
      return filterSearch(s, search, searchColumns);
    });
  }
  return items;
}

function filterSearch(item: any, value: string, columns: Array<string>) {
  // If search columns are passed, search only those fields
  if (columns.length > 0) {
    for (const field of columns) {
      if (item[field] && filterCompareValues(item[field], value)) return true;
    }
  }
  // Otherwise, compare the entire object
  else return filterCompareValues(item, value);
  return false;
}

function filterCompareValues(value1: any, value2: string) {
  return JSON.stringify(value1).toLowerCase().includes(value2);
}

export function generateUniqueKey(item: any) {
  if (isObject(item)) item = JSON.stringify(item);
  return md5(item);
}

export function formatPhoneNumber(phone = "") {
  if (!phone) return "";
  if (phone.length !== 10) return phone;
  return `
    (${phone.substring(0, 3)}) ${phone.substring(3, 6)}
    -${phone.substring(6, 10)}
  `;
}

export function padNumber(number: string | number = 0, length = 0) {
  number = number || "";
  number = number.toString();
  if (number.length >= length) return number;
  while (number.length < length) number = "0" + number;
  return number;
}

export function padLotNumber(lot: string | number = "") {
  return padNumber(lot, 7);
}

export function formatCollectionNumber(number: string | number = "") {
  return "DC/" + padNumber(number, 5);
}

export function formatDeliveryNumber(number: string | number = "") {
  return "DL/" + padNumber(number, 5);
}

export function formatSalesOrderNumber(number: string | number = "") {
  return "SO/" + padNumber(number, 10);
}

export function formatPalletNumber(number: string | number = "") {
  return "PAL/" + padNumber(number, 5);
}

export function formatInvoiceNumber(number: string | number = "") {
  return "INV/" + padNumber(number, 5);
}

export function formatPurchaseOrderNumber(number: string | number = "") {
  return "PO/" + padNumber(number, 10);
}

export function formatPackoutOrderNumber(number: string | number = "") {
  return "PK/" + padNumber(number, 5);
}

export function formatInventoryTransferNumber(number: string | number = "") {
  return "IT/" + padNumber(number, 5);
}

export function formatVarietalNumber(number: string | number = "") {
  return "V/" + padNumber(number, 5);
}

export function formatLotNumber(number: string | number = "") {
  return "LOT/" + padNumber(number, 5);
}

export function round(value: number, decimals: number): number {
  // @ts-ignore JS understands "e".
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(
    decimals
  );
}

// Returns a value with a decimal precision of a specified length
export function getMaxDecimalPrecisionValue(
  value: string,
  length: number = 5
): string {
  const regex = new RegExp(`^\\d*\\.?\\d{0,${length}}$`);
  if (!regex.test(value)) {
    const valueSplit: string[] = value.split(".");
    if (valueSplit.length > 1) {
      valueSplit[1] = valueSplit[1].slice(0, length); // Decimal? Keep only the first X digits
      return valueSplit.join(".");
    } else {
      return valueSplit[0]; // No decimal part? Keep the integer part
    }
  } else {
    return value;
  }
}

export const allowedHtmlTags = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "blockquote",
  "li",
  "ol",
  "p",
  "pre",
  "ul",
  "a",
  "abbr",
  "b",
  "br",
  "em",
  "i",
  "span",
  "strong",
  "u",
];

export function sanitizeHtmlString(value: string): string {
  const disallowedTagsMode: DisallowedTagsModes = "discard";
  const options = {
    allowedTags: allowedHtmlTags,
    disallowedTagsMode,
  };
  return sanitizeHtml(value, options);
}

export function isHtml(value: string): boolean {
  return /(<(\/)?(\w)*(\d)?>)/.test(value);
}

export function allCaps(value: string): string {
  return startCase(camelCase(value));
}

export function findPlot(currentHarvestTask: any, plots: Array<any>) {
  let filter: any = {};
  if (!currentHarvestTask.line_id) {
    filter = plots.find(
      (plot) => plot.rel_plot.uuid === currentHarvestTask.plot_id
    );
  } else {
    filter = plots.find(
      (plot) => plot.rel_plot.line_id === currentHarvestTask.line_id
    );
  }
  return filter;
}

/**
 * consistent lodash rounding for tray counts
 * @param value
 * @param precision
 */
export function roundTrayCount(value: number, precision: number) {
  return Math.ceil(round(value, precision));
}

export function findTimeDiffInHours(
  firstTimestamp: string = "",
  secondTimestamp: string = ""
) {
  return DateTime.fromISO(firstTimestamp).diff(
    DateTime.fromISO(secondTimestamp),
    "hours"
  ).hours;
}
